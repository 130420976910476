<template>
  <div class="text">
    <!-- pic5ECFF2B8C35BB151761B05129A67C20B.jpg -->
    <img src="@/assets/news-network/prof-brickkeepers-new-pet.jpg" class="rounded">
    <p>
      LEGO Universe fans have spoken: "Robo-dog" was by far the most popular type of pet for Brickkeeper in our recent poll!
    </p>
    <p>
      NetDevil designers took on the challenge of designing the Professor's new hound, and now the pup's bounding about the <a href="/community/creation-lab">Creation Lab</a>! Brickkeeper's new robotic pal is in real LEGO scale, so you could even build it with the right pieces!
    </p>
    <p>
      Now it's time to name the Professor's new doggie-bot! LEGOUniverseMaster just opened a message board topic asking for <a href="/messageboards/1960205" target="_blank">ideas</a>! Let us know what you think.
    </p>
  </div>
</template>
